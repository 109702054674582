import { render, staticRenderFns } from "./ProductAccount.vue?vue&type=template&id=235ae900&scoped=true&"
import script from "./ProductAccount.vue?vue&type=script&lang=js&"
export * from "./ProductAccount.vue?vue&type=script&lang=js&"
import style0 from "./ProductAccount.vue?vue&type=style&index=0&id=235ae900&prod&lang=scss&"
import style1 from "./ProductAccount.vue?vue&type=style&index=1&id=235ae900&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235ae900",
  null
  
)

export default component.exports