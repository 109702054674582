<template>
  <main
    class="container_order pa-5"
    :key="selectedReyestrItem && selectedReyestrItem.id"
  >
    <h1 class="account_title ma-0">Price</h1>
    <p class="account_page mb-1">
      <span>
        <span>My account</span>
      </span>
      <span class="pl-1">
        <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
        <span>My Orders</span>
      </span>
      <span class="pl-1">
        <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
        <span>Order</span>
      </span>
    </p>

    <div class="row account_block my-0 mx-n3">
      <div class="col-12 col-sm-4 left_account">
        <ProductImageComponent
          :currentPreviewImage="currentPreviewImage"
          :selectedColor="selectedColorPath"
          :images="selectedProductImages"
          :isSole="reyestrItemCopy.price_type"
        />
        <div v-html="reyestrItemCopy?.video_url" class="video_block"> </div>
      </div>
      <div class="col-sm-8 right_account">
        <div class="row ma-0 right_account-info mb-4">
          <div class="col-sm-12 account_info p-7">
            <p class="info_title">
              {{ this.selectedProduct?.name[this.activeLang?.language] }}
            </p>
            <span class="info_price mb-5">
              ${{ this.choosenPrice && choosenPrice?.price }}
            </span>
            <span
              ref="descriptionRef"
              v-html="truncatedText"
              class="info_product"
            ></span>
            <button @click="toggleText">
              {{ showFullText ? 'Скрыть' : 'Показать еще' }}
            </button>
          </div>

          <div class="col-sm-12 p-7 choose_price">
            <span class="choose"> {{ $t('BRENDING.choosePrice') }}: </span>
            <ul class="choose_ul_li pa-0">
              <li
                v-for="item in priceToQuantityVariations"
                :key="item.id"
                class="item_choose choose_price-item"
                :class="{ 'flex-grow-1': $vuetify.breakpoint.smAndDown }"
              >
                <div
                  v-if="reyestrItemCopy.price_type === 'reyestr'"
                  class="choose_btn"
                  :class="{
                    'choose_btn--active':
                      !reyestrQuantError.error && choosenPrice.id === item.id,
                    'choose_btn--error':
                      isQuantityInvalid && item.id === choosenPrice.id,
                  }"
                >
                  <span> {{ item?.from }} - {{ item.to }} Pairs</span>
                  <span class="green_price">${{ item?.price }}</span>
                </div>
                <div
                  v-else
                  class="choose_btn"
                  :class="{
                    'choose_btn--active':
                      !getSolesErrors && choosenPrice.id === item.id,
                    'choose_btn--error':
                      isQuantityInvalid && item.id === choosenPrice.id,
                  }"
                >
                  <span> {{ item?.from }} - {{ item?.to }} Pairs</span>
                  <span class="green_price">${{ item?.price }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="isTypeSole" class="col-sm-12 p-7 choose_price">
            <span class="choose">Soles:</span>
            <ul class="choose_ul_li pa-0">
              <li
                class="item_choose"
                :class="{ 'flex-grow-1': $vuetify.breakpoint.smAndDown }"
                v-for="sole in reyestrItemCopy.soles"
                :key="sole?.name"
              >
                <button
                  @click="
                    () => {
                      currentSole = sole
                    }
                  "
                  type="button"
                  class="choose_btn"
                  :class="[
                    currentSole?.name === sole?.name
                      ? 'soles_l_grey'
                      : 'soles_grey',
                  ]"
                  :style="
                    `background: url(${reyestrItemCopy.colors[2]?.photo_path})`
                  "
                >
                  <img
                    style="height: 30px; width: 30px; object-fit: contain;"
                    :src="baseUrl + sole.photo_path"
                    alt="sole photo"
                  />
                  <span>{{ sole?.name }}</span>
                </button>
              </li>
            </ul>
          </div>

          <div class="col-sm-12 p-7 choose_colors">
            <span class="colors_ttl">{{ $t('BRENDING.colors') }}:</span>
            <div class="colors_ul_li">
              <template v-if="reyestrItemCopy.price_type === 'sole'">
                <div
                  v-for="(color, index) in reyestrItemCopy.colors.filter(
                    (color) => color.soleId === currentSole.id
                  )"
                  :key="index"
                  class="colors_btn"
                  :class="{
                    'colors_btn--active': color.id === selectedColor.id,
                  }"
                  @click="() => selectColor(color)"
                >
                  <img
                    v-if="color.photo_path"
                    v-lazy="
                      color.photo_path.includes(baseUrl)
                        ? color.photo_path
                        : baseUrl + color.photo_path
                    "
                    alt="photo"
                    class="colors_btn-image"
                    :style="{
                      height: '44px',
                    }"
                  />
                  <div
                    v-else
                    class="colors_btn-image"
                    :style="{
                      height: '44px',
                      backgroundColor: color.css_code,
                    }"
                  />
                </div>
              </template>
              <template v-else>
                <li
                  v-for="(color, index) in reyestrItemCopy.colors"
                  :key="index"
                  class="colors_btn"
                  :class="{
                    'colors_btn--active': color.id === selectedColor.id,
                  }"
                  @click="selectColor(color)"
                >
                  <img
                    v-if="color.photo_path"
                    v-lazy="
                      color.photo_path.includes(baseUrl)
                        ? color.photo_path
                        : baseUrl + color.photo_path
                    "
                    alt="photo"
                    class="colors_btn-image"
                    :style="{
                      height: '44px',
                    }"
                  />
                  <div
                    v-else
                    class="colors_btn-image"
                    :style="{
                      height: '44px',
                      backgroundColor: color.css_code,
                    }"
                  />
                </li>
              </template>
            </div>
          </div>

          <div class="col-sm-12 p-7 choose_size">
            <div class="mb-4 d-flex align-center">
              <span class="size_ttl">
                {{ $t('BRENDING.size') }}:
                <span
                  class="clear_ttl cursor-pointer mr-5"
                  @click="clearSizesCount"
                >
                  Clear size
                </span>
                <span
                  v-b-modal.size-chart-modal
                  @click="getSizeChart(reyestrItemCopy.size_chart_id)"
                  class="clear_ttl cursor-pointer mr-5"
                >
                  Размерная сетка
                </span>
                <b-modal id="size-chart-modal">
                  <div
                    v-html="
                      size_chart && size_chart?.description[activeLang.language]
                    "
                  ></div>
                </b-modal>
              </span>
              <SavingNotification
                v-if="
                  reyestrItemCopy.price_type === 'reyestr' &&
                    showSolesSavingText
                "
                :item="
                  getReyestrSavingComputed && getReyestrSavingComputed.text
                "
                :remainder="
                  getReyestrSavingComputed && getReyestrSavingComputed.remainder
                "
                :saving="
                  getReyestrSavingComputed && getReyestrSavingComputed.saving
                "
              >
                <template #default="{ remainder, saving }">
                  <v-btn
                    class="d-inline accept-offer-btn ml-3"
                    color="#e8d36a"
                    :elevation="0"
                    @click="
                      onAcceptOffer({
                        remainder,
                        saving,
                      })
                    "
                  >
                    Принять
                  </v-btn>
                </template>
              </SavingNotification>
              <SavingNotification
                v-if="
                  reyestrItemCopy.price_type === 'sole' && showSolesSavingText
                "
                :item="getSoleSavingComputed && getSoleSavingComputed.text"
                :remainder="
                  getSoleSavingComputed && getSoleSavingComputed.remainder
                "
                :saving="getSoleSavingComputed && getSoleSavingComputed.saving"
              >
                <template #default="{ remainder, saving }">
                  <v-btn
                    class="d-inline accept-offer-btn ml-3"
                    color="#e8d36a"
                    :elevation="0"
                    @click="
                      onAcceptOffer({
                        remainder,
                        saving,
                      })
                    "
                  >
                    Принять
                  </v-btn>
                </template>
              </SavingNotification>
            </div>
            <ul class="size_ul_li pa-0 row mx-n1 py-0 w-100">
              <template v-if="reyestrItemCopy.price_type === 'sole'">
                <li
                  v-for="(size, index) in reyestrItemCopy.sizes &&
                    reyestrItemCopy.sizes
                      .filter(
                        (size) =>
                          size.colorId === selectedColor.id &&
                          size.soleId === currentSole.id
                      )
                      .sort((a, b) => +a.name - +b.name)"
                  :key="index"
                  class="size_item col-sm-4 pa-1"
                >
                  <div class="btn_choose_size">
                    <div class="size_block">
                      <span class="s_number">{{ size?.name }}</span>
                      <span class="s_number_ttl">size</span>
                    </div>
                    <button type="button" class="btn-size btn btn-number">
                      <v-icon
                        size="11"
                        @click="size.count >= 1 ? size.count-- : ''"
                      >
                        mdi-minus
                      </v-icon>
                      <input
                        type="number"
                        min="0"
                        id="quantity"
                        name="quantity"
                        class="form-control input-number"
                        :value="size.count"
                        @input="
                          (e) => {
                            onChangeCount(e, size)
                          }
                        "
                      />
                      <v-icon
                        size="11"
                        @click="
                          () => {
                            size.count++
                            size.price = choosenPrice?.price
                          }
                        "
                      >
                        mdi-plus
                      </v-icon>
                    </button>
                  </div>
                </li>
              </template>
              <template v-else>
                <li
                  v-for="(size, index) in reyestrItemCopy.sizes &&
                    reyestrItemCopy.sizes
                      .filter((size) => size.colorId === selectedColor.id)
                      .sort((a, b) => +a.name - +b.name)"
                  :key="index"
                  class="size_item col-sm-4 pa-1"
                >
                  <div class="btn_choose_size">
                    <div class="size_block">
                      <span class="s_number">{{ size?.name }}</span>
                      <span class="s_number_ttl">size</span>
                    </div>
                    <button type="button" class="btn-size btn btn-number">
                      <v-icon
                        size="11"
                        @click="size.count >= 1 ? size.count-- : ''"
                      >
                        mdi-minus
                      </v-icon>
                      <input
                        type="number"
                        min="0"
                        id="quantity"
                        name="quantity"
                        class="form-control input-number"
                        :value="size.count"
                        @input="
                          (e) => {
                            onChangeCount(e, size)
                          }
                        "
                      />
                      <v-icon
                        size="11"
                        @click="
                          () => {
                            size.count++
                            size.price = choosenPrice?.price
                          }
                        "
                      >
                        mdi-plus
                      </v-icon>
                    </button>
                  </div>
                </li>
              </template>
            </ul>
          </div>

          <div class="col-sm-12 customization my-4 py-0">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">
                  <span class="customization__title">{{
                    $t('BRENDING.brending_text')
                  }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="row mx-n2 mt-5 ma-0">
                    <div class="col-sm-4 px-2">
                      <div class="customization__radio py-1">
                        <div class="pa-3">
                          <div class="customization__radio-btn">
                            <input
                              v-model="enableLogo"
                              :value="0"
                              type="radio"
                              name="enable_logo"
                            />
                            <span class="checkmark"></span>
                          </div>
                        </div>
                        <div class="customization__radio-text text-truncate">
                          <span>{{ $t('BRENDING.brending_body.place') }}</span>
                          {{ $t('BRENDING.brending_body.price_unchangable') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 px-2">
                      <div class="customization__radio py-1">
                        <div class="pa-3">
                          <div class="customization__radio-btn">
                            <input
                              v-model="enableLogo"
                              :value="1"
                              type="radio"
                              name="enable_logo"
                            />
                            <span class="checkmark"></span>
                          </div>
                        </div>
                        <div class="customization__radio-text text-truncate">
                          <span>{{
                            $t('BRENDING.brending_body.replace')
                          }}</span>
                          {{ $t('BRENDING.brending_body.price_changable') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 px-2">
                      <div class="customization__radio py-1">
                        <div class="pa-3">
                          <div class="customization__radio-btn">
                            <input
                              v-model="enableLogo"
                              :value="2"
                              type="radio"
                              name="enable_logo"
                            />
                            <span class="checkmark"></span>
                          </div>
                        </div>
                        <div class="customization__radio-text text-truncate">
                          <span>{{ $t('BRENDING.brending_body.delete') }}</span>
                          {{ $t('BRENDING.brending_body.price_unchangable') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row mx-n2 ma-0"
                    v-if="reyestrItemCopy.price_type === 'reyestr'"
                  >
                    <div
                      v-for="(item, index) in branding"
                      :key="index"
                      class="col-sm-4 customization__position-item pa-2"
                      :style="getCheckboxStyle(item)"
                      :ref="isDisabled"
                    >
                      <div class="position-item__ratio">
                        <div
                          class="ratio__image"
                          :style="
                            `background: url('${baseUrl}${item.photo}') center / cover no-repeat;`
                          "
                        >
                          <b-button
                            v-b-modal.my-modal
                            @click="getModalValue(item?.description)"
                            class="ratio__button"
                            variant="light"
                            ><b-icon
                              class="ratio__button__icon"
                              icon="exclamation-circle"
                              variant="secondary"
                            ></b-icon
                          ></b-button>
                          <label class="ratio__checkbox" :key="currentSole.id">
                            <input
                              v-if="reyestrItemCopy.price_type === 'reyestr'"
                              type="checkbox"
                              :value="item.id"
                              :v-model="selected_brands?.includes(item)"
                              @change="handleCheckboxChange(item)"
                              :disabled="shouldDisableCheckbox(item)"
                              :style="{
                                opacity: shouldDisableCheckbox(item)
                                  ? '0.5'
                                  : '1',
                              }"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="d-flex justify-space-between">
                        <span>{{ item?.name }}</span>
                        <span>${{ getBrandingPrice(item) }}</span>
                      </div>
                    </div>
                    <b-modal id="my-modal">{{ modalValue }}</b-modal>
                  </div>
                  <div v-else class="d-flex flex-wrap">
                    <div
                      v-for="(item, index) in currentSole.brands"
                      :key="index"
                      class="col-sm-4 customization__position-item pa-2"
                      :style="getCheckboxStyle(item)"
                    >
                      <div class="position-item__ratio">
                        <div
                          class="ratio__image"
                          :style="
                            `background: url('${baseUrl}${item.photo}') center / cover no-repeat;`
                          "
                        >
                          <b-button
                            v-b-modal.my-modal
                            @click="getModalValue(item?.description)"
                            class="ratio__button"
                            variant="light"
                            ><b-icon
                              class="ratio__button__icon"
                              icon="exclamation-circle"
                              variant="secondary"
                            ></b-icon
                          ></b-button>
                          <label class="ratio__checkbox" :key="currentSole.id">
                            <input
                              type="checkbox"
                              :value="item.id"
                              :checked="
                                currentSole?.selected_brands?.includes(item)
                              "
                              @change="handleCheckboxChange(item)"
                              :disabled="shouldDisableCheckbox(item)"
                              :style="{
                                opacity: shouldDisableCheckbox(item)
                                  ? '0.5'
                                  : '1',
                              }"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="d-flex justify-space-between">
                        <span>{{ item?.name }}</span>
                        <span>${{ getBrandingPrice(item) }}</span>
                      </div>
                    </div>
                    <b-modal id="my-modal">{{ modalValue }}</b-modal>
                  </div>
                  <div
                    v-show="enableLogo === 1"
                    class="row justify-end ma-0 mt-5"
                  >
                    <div
                      v-show="uploadedLogo"
                      class="pa-3 col-sm-7"
                      style="border: 1px solid #f1f1f1"
                    >
                      <img
                        style="width: 100%; height: 100%"
                        :src="getLogoPreview(uploadedLogo)"
                        class="pa-0"
                      />
                    </div>
                    <div class="col-sm-5 position-relative">
                      <label
                        for="file"
                        class="upload-logo__btn cursor-pointer mb-0"
                        style="z-index: 1"
                      >
                        Загрузить свой логотип
                      </label>
                      <v-file-input
                        id="file"
                        class="position-absolute"
                        style="opacity: 0; inset: 12px; z-index: -1"
                        @change="onLogoChange"
                      ></v-file-input>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div class="col-sm-12 preliminary_count">
            <p class="count_ttl"> {{ $t('BRENDING.pre_count') }} </p>
            <div v-if="isTypeSole">
              <div
                v-for="(sole, index) in orders.soles"
                :key="index"
                :class="{
                  'mb-3': index !== orders.soles.length - 1,
                }"
              >
                <fieldset>
                  <legend>Подошва: {{ sole && sole?.name }}</legend>
                  <div
                    v-for="(color, index) in orders.colors.filter(
                      (color) => color.soleId === sole.id
                    )"
                    :key="index"
                    class="d-flex flex-column"
                  >
                    <div class="mb-3">
                      <ul>
                        <li>
                          цвет:
                          <span
                            style="
                              width: 10px;
                              height: 10px;
                              display: inline-block;   
                              border-radius:   50%;  
                            "
                            :style="{ backgroundColor: color.css_code }"
                          ></span>
                          {{ color?.name }}
                        </li>
                      </ul>

                      <fieldset class="ma-0 mb-2">
                        <legend>Размеры</legend>
                        <ul style="column-count: 4">
                          <li
                            v-for="(size, index) in orders.sizes.filter(
                              (size) =>
                                size.colorId === color.id &&
                                size.soleId === sole.id
                            )"
                            :key="index"
                          >
                            ${{ size?.price }} * {{ size?.name }} *
                            {{ size.count }}
                          </li>
                        </ul>
                      </fieldset>
                    </div>
                    <SavingNotification
                      v-if="
                        reyestrItemCopy.price_type === 'sole' &&
                          showSolesSavingText
                      "
                      class="align-self-end"
                      :item="
                        getSoleSaving(sole.id, color.id) &&
                          getSoleSaving(sole.id, color.id).text
                      "
                    />
                    <span
                      v-if="getSolesSavingValue(sole.id, color.id)"
                      class="align-self-end"
                    >
                      Экономия ${{ getSolesSavingValue(sole.id, color.id) }}
                    </span>
                  </div>
                  <fieldset class="mt-3">
                    <legend>{{ $t('BRENDING.brending_text') }}</legend>
                    <ul style="column-count: 4" class="pa-0">
                      <!-- <pre>{{ orders.selected_brands }}</pre> -->
                      <li
                        v-for="(brand, index) in sole.selected_brands"
                        :key="index"
                      >
                        <img src="brand.image" alt="" />
                        <div v-if="brand.count">
                          <p>{{ brand.name }}:</p>
                          <p
                            >Цена за пакет: ${{
                              brand?.boughtBrandsKit === 0
                                ? '0'
                                : getPriceDetails(brand).brandsPrice
                            }}</p
                          >
                          <p
                            >Цена за услугу: ${{
                              getPriceDetails(brand).servicePrice
                            }}</p
                          >
                        </div>
                        <p v-else>
                          {{ brand.name }}: ${{ getBrandingPrice(brand) }}</p
                        >
                      </li>
                    </ul>
                  </fieldset>
                  <ul style="float: right" class="d-flex flex-column align-end">
                    <li>
                      Количество товаров =
                      {{ totalQuantity('sole', sole.id) }}
                    </li>
                    <li>
                      <span>Сумма брендирования = </span>
                      ${{ getBrandSum(sole) }}
                    </li>
                    <li>
                      Общая цена = ${{
                        +orders.sizes
                          .filter((size) => size.soleId === sole.id)
                          .reduce(
                            (sum, curr) => (sum += curr.count * curr?.price),
                            0
                          ) + getBrandSum(sole)
                      }}
                    </li>
                  </ul>
                </fieldset>
              </div>
              <!-- <fieldset class="mt-3">
                <legend>{{ $t('BRENDING.brending_text') }}</legend>
                <ul style="column-count: 4" class="pa-0">
                  <li
                    v-for="(brand, index) in selected_brands_objects"
                    :key="index"
                  >
                    {{ brand?.name }} = ${{ brand?.price }}
                  </li>
                </ul>
              </fieldset> -->
            </div>
            <div v-else>
              <div v-if="orders.colors.length">
                <fieldset>
                  <div
                    v-for="(color, index) in orders.colors"
                    :key="index"
                    :class="{ 'mb-5': index !== orders.colors.length - 1 }"
                    class="d-flex flex-column"
                  >
                    <legend>
                      {{ $t('BRENDING.colors') }}:
                      <span
                        style="
                          width: 10px;
                          height: 10px; display: inline-block;  border-radius:   50%;   
                        "
                        :style="{ backgroundColor: color.css_code }"
                      ></span>
                      {{ color && color?.name }}
                    </legend>
                    <fieldset class="ma-0 mb-2">
                      <legend>Размеры</legend>
                      <ul style="column-count: 4">
                        <li
                          v-for="(size, index) in orders.sizes.filter(
                            (size) => size.colorId === color.id
                          )"
                          :key="index"
                        >
                          ${{ size?.price }} * {{ size?.name }} *
                          {{ size.count }}
                        </li>
                      </ul>
                    </fieldset>

                    <SavingNotification
                      v-if="
                        reyestrItemCopy.price_type === 'reyestr' &&
                          showSolesSavingText
                      "
                      class="align-self-end"
                      :item="
                        getReyestrSaving(color.id) &&
                          getReyestrSaving(color.id).text
                      "
                    />
                    <span
                      v-if="getReyestrSavingValue(color.id)"
                      class="align-self-end"
                    >
                      Экономия ${{ getReyestrSavingValue(color.id) }}
                    </span>
                  </div>
                  <ul style="float: right" class="d-flex flex-column align-end">
                    <li>
                      Количество товаров =
                      {{ totalQuantity('reyestr') }}
                    </li>
                    <li>
                      <span>Сумма брендирования = </span>
                      ${{ getBrandSumReyestr() }}
                    </li>
                    <li>
                      Общая цена = ${{
                        orders.sizes.reduce(
                          (sum, curr) => (sum += curr?.price * curr.count),
                          0
                        ) + getBrandSumReyestr()
                      }}
                    </li>
                  </ul>
                </fieldset>
                <fieldset class="mt-3">
                  <legend>Брендирование</legend>
                  <ul style="column-count: 4" class="pa-0">
                    <!-- <pre>{{ orders.selected_brands }}</pre> -->
                    <li
                      v-for="(brand, index) in selected_brands_objects"
                      :key="index"
                    >
                      <img src="brand.image" alt="" />
                      {{ brand.name }}:
                      <p v-if="brand.count"
                        >Цена за пакет: ${{
                          brand?.boughtBrandsKit === 0
                            ? '0'
                            : brand?.brandsPrice
                        }}</p
                      >
                      <p v-if="brand.count"
                        >Цена за услугу: ${{
                          getPriceDetails(brand).servicePrice
                        }}</p
                      >
                      <p v-else>{{ getBrandPrice(brand) }}</p>
                    </li>
                  </ul>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="row no-gutters justify-end">
          <div class="col-sm-5">
            <BaseButton
              :title="$t('BRENDING.add_to_cart')"
              :disabled="isQuantityInvalid"
              @click="handleAdding"
              :style="{ cursor: isQuantityInvalid ? 'no-drop' : '' }"
            />
          </div>
        </div>
      </div>
    </div>
    <BrandingCountModal
      :modal="brandingCount"
      :modalType="brandingCountDetail"
      @closeModal="brandingCount = false"
    >
    </BrandingCountModal>

    <v-dialog
      class="v-dialog"
      :value="isShowProductWarning"
      persistent
      max-width="600"
    >
      <v-card class="modal-card">
        <v-card-title>
          <div class="b-title">
            Важное уведомление!
            <i @click="isShowProductWarning = false" class="fas fa-close"></i>
          </div>
        </v-card-title>
        <v-card-text>
          <p class="b-details">
            {{ warningText }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <CoolLightBox
      :items="selectedProductImages"
      :index="imageIndex"
      @close="imageIndex = null"
    ></CoolLightBox> -->
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import BaseButton from '../layout/BaseButton.vue'
import SavingNotification from '../content/SavingNotification.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductImageComponent from './product-view/ProductImageComponent.vue'
import BrandingCountModal from './store/BrandingCountModal.vue'

const { mapState, mapActions } = createNamespacedHelpers('reyestr')
const {
  mapActions: Actions_cart,
  mapState: State_cart,
} = createNamespacedHelpers('cart')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')
const {
  mapState: State_notif,
  mapActions: Actions_notif,
  mapMutations: Mutations_notif,
} = createNamespacedHelpers('notifications')
const {
  mapActions: Actions_brand_doubt,
  mapState: State_brand_doubt,
} = createNamespacedHelpers('brandDoubt')
const {
  mapState: State_brandings,
  mapActions: Actions_brandings,
} = createNamespacedHelpers('brandings')

const {
  mapState: State_size_crarts,
  mapActions: Actions_size_charts,
} = createNamespacedHelpers('sizeChart')

export default {
  name: 'ProductAccount',
  data() {
    return {
      baseUrl: '',
      activeLang: '',
      showFullText: false,
      maxLength: 400,
      currentBrands: [],
      branding: [],
      selectedColor: {},
      selectedImg: null,
      imageIndex: null,
      currentQuantityPrice: 0,
      currentSole: {},
      currentPreviewImage: '',
      modalValue: '',
      totalCount: 0,

      soles: [
        {
          name: 'Felt',
          price: 4,
        },
        {
          name: 'Felt+Eva',
          price: 5,
        },
      ],
      belowOneHundred: 10.94,
      overTwoHundredPrice: 10.21,
      overThreeHundredPrice: 10.01,
      overOneThousand: 9,
      selectedLogoPositions: [],
      customizationEnabled: true,
      enableLogo: 0,
      preCount: [],
      selectedProduct: null,
      selectedProductImages: [],
      selectedProductSizes: [],
      selectedProductSoles: [],
      selectedProductColors: [],
      selectedProductPrices: [],
      selectedColorPath: '',
      selectedPositions: {},
      isDisabled: false,

      choosenPrice: null,
      uploadedLogo: null,
      isTypeSole: false,
      sizeChartValue: '',

      reyestrItemCopy: {},

      selected_brands: [],
      selected_brand: '',
      orders: {
        sizes: [],
        colors: [],
        soles: [],
        logos: [],
        logo: {},
      },

      validQuant: false,
      boughtBrands: [],

      reyestrQuantError: {
        error: false,
        index: null,
      },
      solesQuantErrors: [],
      reyestrSaving: [],
      solesSaving: [],

      reyestrSavingValues: [],
      solesSavingValues: [],

      brandingCount: false,
      brandingCountDetail: {
        type: '',
        remainder: 0,
      },

      isShowProductWarning: false,
      warningText: '',
    }
  },
  components: {
    BaseButton,
    SavingNotification,
    ProductImageComponent,
    BrandingCountModal,
  },
  computed: {
    ...mapState(['selectedReyestrItem']),
    ...State_cart(['cartItems']),
    ...State_notif(['notifications']),
    ...State_size_crarts(['size_chart']),
    ...State_brand_doubt(['orderDetails']),
    selected_brands_objects() {
      // if (this.reyestrItemCopy.price_type === 'reyestr') {
      const selectedBrands = this.selected_brands.map((type) => {
        const foundItem = this.branding.find((item) => item.id === type.id)
        if (foundItem) {
          return {
            ...foundItem,
            isEnough: type.isEnough,
            reyestrId: type.reyestrId,
            boughtBrandsKit: type.boughtBrandsKit,
            selected_count: type.selected_count,
          }
        } else {
          return type // Возвращаем предыдущий выбранный объект, если его не найдено в обновленном массиве `branding`
        }
      })
      return selectedBrands
    },
    logoPositions() {
      const imgs = [
        '/img/img_logo-customization/position-1.webp',
        '/img/img_logo-customization/position-2.webp',
        '/img/img_logo-customization/position-3.webp',
        '/img/img_logo-customization/position-4.webp',
        '/img/img_logo-customization/position-5.webp',
      ]
      return imgs
    },
    showSolesSavingText() {
      const dif = this.choosenPrice?.to - this.choosenPrice?.from
      const quantityFromToMid = Math.floor(dif / 2)
      const result =
        quantityFromToMid + this.choosenPrice?.from < this.getCurrentQuantity
      return true
    },
    getCurrentQuantity() {
      if (this.reyestrItemCopy.price_type === 'sole') {
        const sum = this.orders.sizes.reduce((sum, curr) => {
          if (curr.soleId === this.currentSole.id) {
            if (curr.colorId === this.selectedColor.id) {
              sum += curr.count
            }
          }
          return sum
        }, 0)
        return sum
      } else {
        const sum = this.orders.sizes.reduce((sum, curr) => {
          if (this.selectedColor.id === curr.colorId) {
            sum += curr.count
          }
          return sum
        }, 0)
        return sum
      }
    },
    totalQuantityForSelectedColor() {
      if (this.reyestrItemCopy.price_type === 'sole') {
        return this.orders.sizes.reduce((acc, curr) => {
          if (this.currentSole.id === curr.soleId) {
            acc += curr.count
          }
          return acc
        }, 0)
      } else {
        return this.orders.sizes.reduce((acc, curr) => {
          acc += curr.count
          return acc
        }, 0)
      }
    },
    totalProductCount() {
      if (this.reyestrItemCopy.price_type === 'sole') {
        return this.orders.sizes.reduce((acc, curr) => {
          acc += curr.count
          return acc
        }, 0)
      } else {
        return this.orders.sizes.reduce((acc, curr) => {
          acc += curr.count
          return acc
        }, 0)
      }
    },
    priceToQuantityVariations() {
      return this.selectedProductPrices.length
        ? this.selectedProductPrices
        : this.currentSole?.prices
    },
    getSolesErrors() {
      const soleError = this.solesQuantErrors.find(
        (error) => error.soleId === this.currentSole.id
      )
      if (soleError) {
        return soleError
      }
      return false
    },
    isQuantityInvalid() {
      let invalid = false
      if (this.reyestrItemCopy?.price_type === 'reyestr') {
        invalid = this.reyestrQuantError.error
      } else {
        if (this.totalQuantity('sole', this.currentSole?.id)) {
          if (!this.solesQuantErrors.length) {
            invalid = false
          } else {
            invalid = true
          }
        } else {
          invalid = true
        }
      }
      return invalid
    },
    getSoleSavingComputed() {
      const item = this.solesSaving.find(
        (item) =>
          item.id === this.currentSole.id &&
          item.colorId === this.selectedColor.id
      )
      return item && item
    },
    getReyestrSavingComputed() {
      const item = this.reyestrSaving.find(
        (item) => item.colorId === this.selectedColor.id
      )
      return item && item
    },
    truncatedText() {
      return this.showFullText
        ? this.selectedProduct?.description[this.activeLang.language]
        : this.selectedProduct?.description[this.activeLang.language].substring(
            0,
            this.maxLength2
          )
    },
  },
  watch: {
    // enableLogo(val) {
    //   if (val !== 0) {
    //     this.customizationEnabled = false
    //   } else {
    //     this.customizationEnabled = true
    //   }
    // },
    selectedReyestrItem(val) {
      this.selectedProduct = cloneDeep(val)
      if (val && val.soles.length) {
        this.isTypeSole = true
      }

      const copy = cloneDeep(val)
      let sizes = []
      if (copy.price_type === 'sole') {
        let colors = []
        copy.soles.forEach((sole) => {
          copy.colors.forEach((color) => {
            const orderId = Date.now() * Math.floor(Math.random() * 10)
            color = { ...color, soleId: sole.id, orderId }
            colors.push(color)
          })
        })
        copy.soles = copy.soles.map((el, index) => {
          return {
            ...el,
            selected_brands: [],
            index,
          }
        })
        copy.colors = colors
        copy.colors.forEach((color) => {
          copy.sizes.forEach((size) => {
            const orderId = Date.now() * Math.floor(Math.random() * 10)
            size = {
              ...size,
              colorId: color.id,
              soleId: color.soleId,
              orderId,
              count: 0,
              price: undefined,
            }
            sizes.push(size)
          })
        })
        copy.sizes = sizes

        this.currentSole = copy.soles[0]
        this.selectedColor = copy.colors.filter(
          (color) => color.soleId === this.currentSole.id
        )[0]
      } else {
        copy.colors.forEach((color) => {
          copy.sizes.forEach((size) => {
            const orderId = Date.now() * Math.floor(Math.random() * 10)
            size = {
              ...size,
              colorId: color.id,
              orderId,
              count: 0,
              price: undefined,
            }
            sizes.push(size)
          })
        })

        copy.sizes = sizes
        this.selectedColor = copy.colors[0]
      }
      this.reyestrItemCopy = copy
    },
    reyestrItemCopy: {
      deep: true,
      handler(val) {
        const item = cloneDeep(val)
        if (item.price_type === 'sole') {
          item.sizes = item.sizes.filter((size) => size.count)
          item.colors = item.colors.filter(
            (color) =>
              item.sizes.filter(
                (size) =>
                  size.colorId === color.id && size.soleId === color.soleId
              ).length
          )
          item.soles = item.soles.filter(
            (sole) =>
              item.colors.filter((color) => color.soleId === sole.id).length
          )
          this.orders.sizes = item.sizes
          this.orders.colors = item.colors
          this.orders.soles = item.soles
        } else {
          item.sizes = item.sizes.filter((size) => size.count)
          item.colors = item.colors.filter(
            (color) =>
              item.sizes.filter((size) => size.colorId === color.id).length
          )
          this.orders.sizes = item.sizes
          this.orders.colors = item.colors
        }
      },
    },
    // async currentSole(val) {
    //   if (val) {
    //     const { currentSole, fetchBrandingById, reyestrItemCopy } = this
    //     this.selectedColor = reyestrItemCopy.colors[0]
    //     val.prices.sort((a, b) => (a.from > b.from ? 1 : -1))
    //     this.choosenPrice = val.prices[0]
    //     if (reyestrItemCopy.price_type === 'reyestr') {
    //       for (let i = 0; i < currentSole.brands.length; i++) {
    //         const position = await fetchBrandingById(currentSole.brands[i].id)
    //         const existingPos = this.branding.find(
    //           (el) => el.id === position.id
    //         )
    //         if (!existingPos) {
    //           this.branding.push(position)
    //         }
    //       }
    //     } else {
    //       this.branding = val?.brands
    //       this.currentBrands = []
    //     }
    //   }
    // },
    async currentSole(val) {
      if (val) {
        const { currentSole, fetchBrandingById, reyestrItemCopy } = this
        this.selectedColor = reyestrItemCopy.colors[0]
        val.prices.sort((a, b) => (a.from > b.from ? 1 : -1))
        this.choosenPrice = val.prices[0]
        // Добавляем новые значения в существующий массив this.branding
        val.brands.forEach((el) => {
          el.isDisabled = true
          el.brandPrice = 0
          el.reyestrId = this.reyestrItemCopy?.id
        })
        this.branding =
          val?.brands.map((el) => {
            return {
              ...el,
              sole_name: val?.name,
            }
          }) || []
        this.currentBrands = []
        this.currentPreviewImage = val?.photo_path
        this.assignSelectedImages()
      }
    },
    choosenPrice: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.reyestrItemCopy.price_type === 'sole') {
            this.reyestrItemCopy.sizes.forEach((size) => {
              const colorId = this.selectedColor.id
              const soleId = this.currentSole.id
              if (size.colorId === colorId && size.soleId === soleId) {
                size.price = val.price
              }
            })
          } else {
            this.reyestrItemCopy.sizes.forEach((size) => {
              const colorId = this.selectedColor.id
              if (size.colorId === colorId) {
                size.price = val.price
              }
            })
          }
        }
      },
    },

    getCurrentQuantity(val) {
      const firstIndex = 0
      const lastIndex = this.priceToQuantityVariations.length - 1

      const firstItem = this.priceToQuantityVariations[firstIndex]
      const lastItem = this.priceToQuantityVariations[lastIndex]
      if (val !== 0 && val < firstItem.from) {
        // without sole
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          this.reyestrQuantError.error = true
          this.reyestrQuantError.index = 0
          this.choosenPrice = firstItem
        }
        // with sole
        else {
          this.choosenPrice = firstItem
          const existingError = this.solesQuantErrors.find(
            (error) => error.soleId === this.currentSole.id
          )
          if (existingError) {
            existingError.index = firstIndex
          } else {
            this.solesQuantErrors.push({
              soleId: this.currentSole.id,
              colorId: this.selectedColor.id,
              index: firstIndex,
            })
          }
        }
        this.resetSavingNotifications()
        return
      } else if (val > lastItem.to) {
        // without sole
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          this.choosenPrice = lastItem
        }
        // with sole
        else {
          this.choosenPrice = lastItem
        }
        this.resetQuantErrors()
        this.resetSavingNotifications()
        return
      }
      this.resetQuantErrors()
      this.findCurrentPrice(val)
    },
  },
  methods: {
    ...mapActions(['setSelectedProduct', 'fetchReyestrItemById']),
    ...Actions_cart(['addCartItem', 'toggleSideCart']),
    ...Actions_alerts(['addAlert']),
    ...Actions_notif(['addNotification']),
    ...Actions_brandings(['fetchBrandingById']),
    ...Actions_size_charts(['getSizeChartById']),
    ...Mutations_notif(['SET_NOTIFICATIONS']),
    ...Actions_brand_doubt([
      // 'saveBrandCount',
      'fetchOrderDetailsById',
      'fetchOrderDetails',
    ]),

    getCheckboxStyle(item) {
      if (this.shouldDisableCheckbox(item) || item.isDisabled) {
        return { opacity: '0.5' } // Устанавливаем серый фон для отключенных позиций
      } else {
        return {} // Пустой объект, если чекбокс активный
      }
    },
    getBrandPrice(brand) {
      if (+brand.count > 0) {
        const parts = brand?.price?.split('/')
        return +parts[1]
      }
      let price =
        this.getBrandingPrice(brand) * this.totalQuantityForSelectedColor
      return brand.brandPrice === 0 || isNaN(price) ? 0 : price
    },
    updateCheckboxState() {
      // Обновите состояние всех чекбоксов в соответствии с массивом selected_brands
      // Примерно так:
      const checkboxes = document.querySelectorAll(
        '.ratio__checkbox input[type="checkbox"]'
      )
      checkboxes.forEach((checkbox) => {
        const item = checkbox.value
        if (this.reyestrItemCopy.price_type === 'sole') {
          checkbox.checked = this.currentSole.selected_brands.includes(item)
        } else {
          checkbox.checked = this.selected_brands.includes(item)
        }
      })
    },

    changeBoughtBrands(selectedBrands) {
      const savedBrands = JSON.parse(localStorage.getItem('bought_brands'))
      let brands = []
      selectedBrands.forEach((brand) => {
        const existingBrand = savedBrands.find((elem) => elem.id === brand.id)
        if (existingBrand) {
          brands = savedBrands?.map((elem) => {
            if (elem?.id === brand.id) {
              if (elem?.remainder >= +brand?.selected_count) {
                return {
                  ...elem,
                  remainder: elem.remainder - +brand?.selected_count,
                  selected_count: elem?.selected_count
                    ? elem?.selected_count + +brand?.selected_count
                    : +brand?.selected_count,
                }
              } else {
                return {
                  ...elem,
                  remainder:
                    elem?.remainder > brand.selected_count
                      ? elem?.remainder - +brand?.selected_count
                      : Math.ceil(
                          (brand.selected_count - elem?.remainder) /
                            +brand.count
                        ) *
                          +brand.count +
                        elem?.remainder -
                        brand.selected_count,
                  selected_count: elem?.selected_count
                    ? elem?.selected_count + +brand?.selected_count
                    : +brand?.selected_count,
                  boughtBrandsKit: +elem?.boughtBrandsKit
                    ? +elem?.boughtBrandsKit +
                      brand.selected_count -
                      elem.remainder
                    : brand.selected_count - elem.remainder,
                }
              }
            } else return elem
          })
        } else {
          const existingBrand = this.orderDetails?.find(
            (el) => el.brand_id === brand.id
          )
          if (existingBrand) {
            brands = savedBrands
            if (brand?.boughtBrandsKit === 0) {
              brands.push({
                ...brand,
                remainder:
                  existingBrand.current_employee_branding.curren_amount -
                  brand?.selected_count,
                selected_count: brand?.selected_count,
                starter_remainder:
                  existingBrand.current_employee_branding.curren_amount,
              })
            } else {
              brands.push({
                ...brand,
                remainder:
                  existingBrand.current_employee_branding.curren_amount >
                  brand.selected_count
                    ? existingBrand.current_employee_branding.curren_amount -
                      +brand?.selected_count
                    : Math.ceil(
                        (brand.selected_count -
                          existingBrand.current_employee_branding
                            .curren_amount) /
                          +brand.count
                      ) *
                        +brand.count +
                      existingBrand.current_employee_branding.curren_amount -
                      brand.selected_count,
                selected_count: +brand?.selected_count,
                boughtBrandsKit:
                  brand.selected_count -
                  existingBrand.current_employee_branding.curren_amount,
                starter_remainder:
                  existingBrand.current_employee_branding.curren_amount,
              })
            }
          } else {
            brands.push({
              ...brand,
              remainder:
                +brand.count > brand.selected_count
                  ? +brand.count - +brand?.selected_count
                  : Math.ceil(brand.selected_count / +brand.count) *
                      +brand.count -
                    brand.selected_count,
              selected_count: +brand?.selected_count,
              boughtBrandsKit: brand.selected_count,
              starter_remainder:
                +brand.count > brand.selected_count
                  ? +brand.count
                  : Math.ceil(brand.selected_count / +brand.count) *
                    +brand.count,
            })
          }
        }
      })
      localStorage.setItem('bought_brands', JSON.stringify(brands))
    },

    async handleCheckboxChange(item) {
      try {
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          const existingItem = this.selected_brands.find(
            (el) => el.id === item.id
          )
          let currentAmount

          if (existingItem) {
            this.selected_brands = this.selected_brands.filter(
              (brand) => brand.id !== item.id
            )
            this.brandingCountDetail.type = ''
            this.brandingCount = false
          } else {
            const savedBrands = JSON.parse(
              localStorage.getItem('bought_brands')
            )
            const orderDetail = this.orderDetails?.find(
              (el) => el.brand_id === item.id
            )
            const existingBrand = savedBrands.find((el) => el.id === item.id)
            if (existingBrand) {
              currentAmount = existingBrand?.remainder
            } else if (orderDetail) {
              currentAmount =
                orderDetail?.current_employee_branding?.curren_amount
            } else {
              currentAmount = 0
            }

            if (currentAmount >= this.totalQuantityForSelectedColor) {
              this.brandingCountDetail.type = 'enough'
              this.brandingCount = true
            } else if (
              currentAmount < this.totalQuantityForSelectedColor &&
              currentAmount !== 0
            ) {
              this.brandingCountDetail.type = 'not enough'
              this.brandingCount = true
            } else {
              this.brandingCount = false
              this.brandingCountDetail.type = 'not enough'
            }
            this.brandingCountDetail.remainder = currentAmount
            let boughtBrandsKit
            if (this.totalQuantityForSelectedColor > currentAmount) {
              boughtBrandsKit = Math.ceil(
                (this.totalQuantityForSelectedColor - currentAmount) /
                  +item.count
              )
            } else {
              boughtBrandsKit = 0
            }

            this.selected_brands.push({
              ...item,
              isEnough: this.brandingCountDetail.type,
              selected_count: this.totalQuantityForSelectedColor,
              boughtBrandsKit: boughtBrandsKit,
            })
          }
        } else {
          const existingItem = this.currentSole.selected_brands.find(
            (el) => el.id === item.id
          )
          let currentAmount

          if (existingItem) {
            this.currentSole.selected_brands = this.currentSole.selected_brands.filter(
              (brand) => brand.id !== item.id
            )
            this.brandingCountDetail.type = ''
            this.brandingCount = false
          } else {
            const savedBrands = JSON.parse(
              localStorage.getItem('bought_brands')
            )
            const orderDetail = this.orderDetails?.find(
              (el) => el.brand_id === item.id
            )
            const existingBrand = savedBrands.find((el) => el.id === item.id)
            if (existingBrand) {
              currentAmount = existingBrand?.remainder
            } else if (orderDetail) {
              currentAmount =
                orderDetail?.current_employee_branding?.curren_amount
            } else {
              currentAmount = 0
            }

            if (currentAmount >= this.totalQuantityForSelectedColor) {
              this.brandingCountDetail.type = 'enough'
              this.brandingCount = true
            } else if (
              currentAmount < this.totalQuantityForSelectedColor &&
              currentAmount !== 0
            ) {
              this.brandingCountDetail.type = 'not enough'
              this.brandingCount = true
            } else {
              this.brandingCount = false
              this.brandingCountDetail.type = 'not enough'
            }
            this.brandingCountDetail.remainder = currentAmount
            let boughtBrandsKit
            if (this.totalQuantityForSelectedColor > currentAmount) {
              boughtBrandsKit = Math.ceil(
                (this.totalQuantityForSelectedColor - currentAmount) /
                  +item.count
              )
            } else {
              boughtBrandsKit = 0
            }

            this.reyestrItemCopy.soles[
              this.currentSole?.index
            ]?.selected_brands.push({
              ...item,
              isEnough: this.brandingCountDetail.type,
              selected_count: this.totalQuantityForSelectedColor,
              boughtBrandsKit: boughtBrandsKit,
            })
          }
        }
      } catch (error) {
        // console.error('Произошла ошибка:', error)
      }
    },
    onChangeCount(e, size) {
      if (this.reyestrItemCopy.price_type === 'sole') {
        this.$set(this.currentSole, 'selected_brands', [])
        this.updateCheckboxState()
      } else {
        this.selected_brands = []
        this.updateCheckboxState()
      }
      size.count = +e.target.value
      size.price = this.choosenPrice?.price

      // await this.$nextTick()
      // let totalCount
      this.onChangeCountBody()
    },
    getPriceDetails(brand) {
      const parts = brand?.price?.split('/')
      return {
        brandsPrice: +parts[0] * brand?.boughtBrandsKit,
        servicePrice: parts[1],
      }
    },
    getBrandingPrice(item) {
      const parts = item?.price?.split('/')
      if (item.count) {
        const existingBrand = this.orderDetails.find(
          (el) => el.brand_id === item.id
        )
        const currentAmount =
          existingBrand?.current_employee_branding?.curren_amount
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          this.branding.forEach((elem) => {
            if (elem.id === item.id) {
              elem.brandPrice = +parts[1]
              elem.brandsPrice = +parts[0]
            } else {
              elem
            }
          })
        } else {
          this.currentSole.brands.forEach((elem) => {
            if (elem.id === item.id) {
              elem.brandPrice = +parts[1]
              elem.brandsPrice = +parts[0]
            } else {
              elem
            }
          })
        }
        return +parts[1]
      }
      let materialPrice
      let servicePrice

      if (this.reyestrItemCopy.price_type === 'reyestr') {
        if (parts?.length === 2) {
          materialPrice = parseFloat(parts[0])
          servicePrice = parseFloat(parts[1])
        } else {
          materialPrice = 0
          servicePrice = 0
        }
        const price =
          materialPrice / this.totalQuantityForSelectedColor + servicePrice
        this.branding.forEach((elem) => {
          elem.id === item.id
            ? (elem.brandPrice = +price.toFixed(2))
            : elem.brandPrice
        })

        return isNaN(price) ? 0 : +price.toFixed(2)
      } else {
        const parts = item?.price?.split('/')
        if (parts?.length === 2) {
          materialPrice = parseFloat(parts[0])
          servicePrice = parseFloat(parts[1])
        } else {
          materialPrice = 0
          servicePrice = 0
        }
        const price =
          materialPrice / this.totalQuantityForSelectedColor + servicePrice
        this.currentSole.brands.forEach((elem) => {
          elem.id === item.id
            ? (elem.brandPrice = +price.toFixed(2))
            : elem.brandPrice
        })
        return isNaN(+price) || +price === Infinity ? 0 : +price.toFixed(2)
      }
    },

    toggleText() {
      this.showFullText = !this.showFullText
    },
    onChangeCountBody() {
      if (this.reyestrItemCopy.price_type === 'sole') {
        this.currentSole.brands.forEach((item) => {
          if (this.totalQuantityForSelectedColor >= +item.min_count) {
            item.isDisabled = false
          } else {
            item.isDisabled = true
          }
        })
      } else {
        this.branding?.forEach((item) => {
          if (this.totalQuantityForSelectedColor >= +item.min_count) {
            item.isDisabled = false
          } else {
            item.isDisabled = true
          }
        })
      }
    },
    getBrandSum(sole) {
      let price
      sole = this.currentSole
      price = sole.selected_brands.reduce((sum, curr) => {
        // const existingItem = boughtBrands.find((el) => el.id === curr.id)
        // if (!existingItem) {
        //   return 0
        // }
        if (curr.count) {
          if (curr.isEnough === 'enough') {
            return (sum += Number(
              curr?.brandPrice * this.totalQuantityForSelectedColor
            ))
          } else {
            return (sum += Number(
              curr?.brandPrice * this.totalQuantityForSelectedColor +
                this.getPriceDetails(curr)?.brandsPrice
            ))
          }
        } else {
          return (sum += Number(
            curr?.brandPrice * this.totalQuantityForSelectedColor
          ))
        }
      }, 0)
      return price === 0 ? 0 : +price.toFixed(2)
    },
    getBrandSumReyestr() {
      let price
      price = this.selected_brands_objects
        .reduce((sum, curr) => {
          if (curr.count) {
            if (curr.isEnough === 'enough') {
              return (sum += Number(
                curr?.brandPrice * this.totalQuantityForSelectedColor
              ))
            } else {
              return (sum += Number(
                curr?.brandPrice * this.totalQuantityForSelectedColor +
                  curr?.brandsPrice
              ))
            }
          } else {
            return (sum += Number(
              curr?.brandPrice * this.totalQuantityForSelectedColor
            ))
          }
        }, 0)
        .toFixed(2)
      return price === 0 ? 0 : Number(price)
    },

    shouldDisableCheckbox(item) {
      this.onChangeCountBody()
      if (this.reyestrItemCopy.price_type === 'reyestr') {
        if (item.isDisabled) {
          return true
        }
        if (this.selected_brands.length === 0) {
          // Если нет выбранных брендов, чекбокс активен
          return false
        } else if (item.type === '') {
          // Если тип товара пустой, чекбокс активен
          return false
        } else {
          // Иначе, проверяем тип товара и сравниваем с типами выбранных брендов
          const selectedBrandTypes = this.branding
            ?.filter((brand) =>
              this.selected_brands.some(
                (selectedBrand) => selectedBrand.id === brand.id
              )
            )
            .map((brand) => brand.type)
          return !selectedBrandTypes.includes(item.type)
        }
      } else {
        if (item.isDisabled) {
          return true
        }
        if (this.currentSole.selected_brands.length === 0) {
          // Если нет выбранных брендов, чекбокс активен
          return false
        } else if (item.type === '') {
          // Если тип товара пустой, чекбокс активен
          return false
        } else {
          // Иначе, проверяем тип товара и сравниваем с типами выбранных брендов
          const selectedBrandTypes = this.currentSole.brands
            ?.filter((brand) =>
              this.currentSole.selected_brands.some(
                (selectedBrand) => selectedBrand.id === brand.id
              )
            )
            .map((brand) => brand.type)

          return !selectedBrandTypes.includes(item.type)
        }
      }
    },
    getModalValue(value) {
      this.modalValue = value
    },
    getSizeChart(value) {
      this.getSizeChartById(value)
      this.sizeChartValue = this.size_chart
    },
    getSoleSaving(soleId, colorId) {
      const item = this.solesSaving.find(
        (item) => item.id === soleId && item.colorId === colorId
      )
      return item && item
    },
    getReyestrSaving(colorId) {
      const item = this.reyestrSaving.find((item) => item.colorId === colorId)
      return item && item
    },
    openImage() {
      this.imageIndex = this.selectedImg.index
    },
    selectColor(color) {
      this.selectedColor = color

      // let lastThreeChars = color.photo_path.slice(-3)
      // let result = color.photo_path.slice(0, -3) + lastThreeChars.toLowerCase()
      this.selectedColorPath = encodeURI(this.baseUrl + color.photo_path)
      this.onChangeCountBody()
    },
    selectImage({ img, index }) {
      w
      this.selectedImg = img
      this.selectedImg.index = index
    },
    clearSizesCount() {
      if (this.reyestrItemCopy.price_type === 'sole') {
        this.reyestrItemCopy.sizes.forEach((size) => {
          if (
            size.colorId === this.selectedColor.id &&
            size.soleId === this.currentSole.id
          ) {
            size.count = 0
          }
        })
        this.solesSaving = this.solesSaving.filter(
          (item) => item.id !== this.currentSole.id
        )
      } else {
        this.reyestrItemCopy.sizes.forEach((size) => {
          if (size.colorId === this.selectedColor.id) {
            size.count = 0
          }
        })
        this.reyestrSaving = this.reyestrSaving.filter(
          (item) => item.colorId !== this.selectedColor.id
        )
      }
      this.choosenPrice = this.priceToQuantityVariations[0]
    },
    handleAdding() {
      if (!this.isQuantityInvalid) {
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          this.orders.selected_brands = this.selected_brands_objects
        } else {
          this.orders.selected_brands = this.reyestrItemCopy.soles
        }
        this.orders.id = this.reyestrItemCopy?.id
        this.orders.brandId = this.reyestrItemCopy?.id
        this.orders.name = this.reyestrItemCopy?.name
        this.orders.price_type = this.reyestrItemCopy.price_type
        this.orders.photo = this.reyestrItemCopy?.photo
        this.orders.reyestrItem = cloneDeep(this.reyestrItemCopy)
        this.orders.from = 'reyestr'

        if (this.reyestrItemCopy.price_type === 'sole') {
          this.reyestrItemCopy.soles.forEach((el) => {
            this.orders.colors.forEach((item) => {
              if (item.soleId === el.id) {
                item.selected_brands = el?.selected_brands
              }
            })
          })
        } else {
          this.orders.colors.forEach((item) => {
            item.selected_brands = this.selected_brands_objects
          })
        }
        this.orders.logo = {
          logo: this.uploadedLogo,
          preview: this.getLogoPreview(this.uploadedLogo),
          positions: this.logoPositions,
        }
        let filteredBrands
        if (this.reyestrItemCopy.price_type === 'reyestr') {
          filteredBrands = this.selected_brands.filter((item) => item.count)
        } else {
          filteredBrands = this.reyestrItemCopy.soles
            .map((item) => item.selected_brands)
            .flat()
            .filter((item) => item.count)
        }
        // this.saveBrandCount(filteredBrands)

        const filteredProducts = this.cartItems.filter(
          (item) => item.from === 'price_list'
        )
        if (filteredProducts.length === 0) {
          this.changeBoughtBrands(filteredBrands)
          this.addCartItem(cloneDeep(this.orders))
          this.toggleSideCart(true)
        } else if (filteredProducts.length) {
          this.warningText = `
          Вы добавили товар из Прайс листа, вам нужно завершить заказ в Прайс листе чтобы продолжить!
          `
          this.isShowProductWarning = true
        }
        this.selected_brands = []
        this.orders = {
          colors: [],
          sizes: [],
          soles: [],
          logos: [],
          logo: {},
        }
        this.reyestrItemCopy.soles.forEach((item) => {
          item.selected_brands = []
        })
        this.currentSole.selected_brands = []
        this.reyestrItemCopy.sizes.forEach((item) => {
          item.count = 0
        })
        if (this.reyestrItemCopy.price_type === 'sole') {
          this.$set(this.currentSole, 'selected_brands', [])
          this.updateCheckboxState()
        } else {
          this.selected_brands = []
          this.updateCheckboxState()
        }
      }
    },
    assignSelectedImages() {
      this.selectedProductImages = this.selectedProduct.gallery.map(
        (item) => this.baseUrl + item
      )

      if (this.reyestrItemCopy.price_type === 'sole') {
        const sole = this.baseUrl + this.currentSole.photo_path
        this.selectedProductImages.unshift(sole)
      }
    },
    onLogoChange(e) {
      if (!e) {
        this.uploadedLogo = null
        return
      }
      this.uploadedLogo = e
      const logo = {
        logo: e,
        color_id: this.selectedColor.id,
      }
      if (this.reyestrItemCopy.price_type === 'sole') {
        logo.sole_id = this.currentSole.id
        // проверка на существование
        // если есть то splice и return
        /**
         * лого может быть столько же сколько и позиций брендирования: на каждую подошву/цвет
         */
        if (
          this.orders.logos.findIndex(
            (logo) =>
              logo.color_id === logo.color_id && logo.sole_id === logo.sole_id
          ) > -1
        ) {
          this.orders.logos.splice(
            this.orders.logos.findIndex(
              (logo) => logo.color_id === logo.color_id
            ),
            1,
            logo
          )

          return
        }
      } else {
        // проверка на существование
        // если есть то splice и return
        if (
          this.orders.logos.findIndex(
            (logo) => logo.color_id === logo.color_id
          ) > -1
        ) {
          this.orders.logos.splice(
            this.orders.logos.findIndex(
              (logo) => logo.color_id === logo.color_id
            ),
            1,
            logo
          )
          return
        }
      }
      this.orders.logos.push(logo)
    },
    getLogoPreview(value) {
      return value && URL.createObjectURL(value)
    },
    totalQuantity(type, id) {
      let total
      if (type === 'sole') {
        total = this.orders.sizes
          .filter((size) => size.soleId === id)
          .reduce((sum, curr) => (sum += curr.count), 0)
      } else if (type === 'reyestr') {
        total = this.orders.sizes.reduce((sum, curr) => (sum += curr.count), 0)
      }
      return total
    },
    resetQuantErrors() {
      if (this.reyestrItemCopy.price_type === 'reyestr') {
        this.reyestrQuantError.error = false
        this.reyestrQuantError.index = null
      } else {
        this.solesQuantErrors = this.solesQuantErrors.filter(
          (error) => error.soleId !== this.currentSole.id
        )
      }
    },
    findCurrentPrice(quantity) {
      const priceType = this.reyestrItemCopy.price_type
      this.priceToQuantityVariations.forEach((price, index) => {
        const nextItem = this.priceToQuantityVariations[index + 1]
        // поиск нужной цены
        if (quantity > price.from) {
          if (quantity <= price.to) {
            this.choosenPrice = price
            if (nextItem) {
              const dif = price?.to - price?.from
              const quantityFromToMid = Math.floor(dif / 2)
              const result = quantityFromToMid + price?.from < quantity
              // вычисление экономии для товара без подошвы
              if (priceType === 'reyestr') {
                // const saving =
                const saving =
                  quantity * price?.price - quantity * nextItem?.price
                const remainder = nextItem.from - quantity + 1
                const text = `Наберите ещё ${remainder} товаров чтобы сэкономить $${saving}`
                const existingSaving = this.reyestrSaving.find(
                  (item) => item.colorId === this.selectedColor.id
                )
                if (existingSaving) {
                  if (result) {
                    existingSaving.saving = saving
                    existingSaving.remainder = remainder
                    existingSaving.text = text
                  }
                } else {
                  if (result) {
                    this.reyestrSaving.push({
                      saving:
                        quantity * price?.price - quantity * nextItem?.price,
                      remainder: nextItem.from - quantity + 1,
                      text: `Наберите ещё ${remainder} товаров чтобы сэкономить $${saving}`,
                      colorId: this.selectedColor.id,
                    })
                  }
                }
                if (!result) {
                  this.reyestrSaving = this.reyestrSaving.filter(
                    (item) => item.colorId !== this.selectedColor.id
                  )
                }
              }
              // вычисление экономии для товара с подошвой
              else {
                const id = this.currentSole.id
                const colorId = this.selectedColor.id
                const saving =
                  quantity * price?.price - quantity * nextItem?.price
                const remainder = nextItem.from - quantity + 1
                const text = `Наберите ещё ${remainder} товаров чтобы сэкономить $${saving}`
                const existingSaving = this.solesSaving.find(
                  (item) =>
                    item.id === this.currentSole.id &&
                    item.colorId === this.selectedColor.id
                )
                if (existingSaving) {
                  if (result) {
                    existingSaving.saving = saving
                    existingSaving.remainder = remainder
                    existingSaving.text = text
                  }
                } else {
                  if (result) {
                    this.solesSaving.push({
                      id,
                      colorId,
                      saving,
                      remainder,
                      text,
                    })
                  }
                }
                if (!result) {
                  this.solesSaving = this.solesSaving
                    .filter((item) => item.id === this.currentSole.id)
                    .filter((item) => item.colorId !== this.selectedColor.id)
                }
              }
            } else {
              this.resetSavingNotifications()
            }
          }
        }
      })
    },
    resetSavingNotifications() {
      const { price_type } = this.reyestrItemCopy
      // удаление экономии последней цены для товара без подошвы
      if (price_type === 'reyestr') {
        this.reyestrSaving = this.reyestrSaving.filter(
          (item) => item.colorId !== this.selectedColor.id
        )
      }
      // удаление экономии последней цены для товара с подошвой
      else {
        this.solesSaving = this.solesSaving
          .filter((item) => item.id === this.currentSole.id)
          .filter((item) => item.colorId !== this.selectedColor.id)
      }
    },
    onAcceptOffer({ remainder, saving }) {
      const { price_type } = this.reyestrItemCopy
      let size = null
      const savingId = Math.floor(Date.now() * Math.random())
      const savingItem = {
        colorId: this.selectedColor.id,
        saving,
        id: savingId,
        productId: this.reyestrItemCopy.id,
        priceType: this.reyestrItemCopy.price_type,
      }
      const supplyName = this.reyestrItemCopy?.name
      const colorName = this.selectedColor?.name
      const currentSoleName = this.currentSole?.name
      const notificationText = `Экономия $${saving}. Вы приняли предложение по увеличению количества товара
      "${supplyName[this.activeLang.language]} + ${
        price_type === 'sole' ? currentSoleName + ' +' : ''
      } ${colorName}" на ${remainder} шт.`

      const notification = {
        text: notificationText,
        id: Date.now() * (Math.random() * 10),
        saving,
      }
      try {
        if (price_type === 'reyestr') {
          size = this.reyestrItemCopy.sizes.find(
            (size) => size.colorId === this.selectedColor.id
          )
          const existingSaving = this.reyestrSavingValues.find(
            (item) => item.colorId === this.selectedColor.id
          )
          if (existingSaving) {
            existingSaving.saving = saving
          } else {
            this.reyestrSavingValues.push(savingItem)
          }
        } else {
          const existingSaving = this.solesSavingValues.find(
            (item) =>
              item.soleId === this.currentSole.id &&
              item.colorId === this.selectedColor.id
          )
          savingItem.soleId = this.currentSole.id
          if (existingSaving) {
            existingSaving.saving = saving
          } else {
            this.solesSavingValues.push(savingItem)
          }
          size = this.reyestrItemCopy.sizes
            .filter((size) => size.soleId === this.currentSole.id)
            .find((size) => size.colorId === this.selectedColor.id)
        }
        size.count += remainder
        this.addAlert({ text: 'Accepted', type: 'success' })
        this.addNotification(notification)
      } catch {
        this.addAlert({ text: 'что-то пошло не так', type: 'error' })
      }
    },

    getReyestrSavingValue(colorId) {
      const saving = this.reyestrSavingValues.find(
        (item) => item.colorId === colorId
      )

      if (saving) return saving.saving
      return undefined
    },
    getSolesSavingValue(soleId, colorId) {
      const saving = this.solesSavingValues.find(
        (item) => item.soleId === soleId && item.colorId === colorId
      )

      if (saving) {
        return saving.saving
      }
      return undefined
    },
  },
  async created() {
    await this.fetchReyestrItemById(this.$route.params.id)
    this.assignSelectedImages()
    this.selectedProductPrices = cloneDeep(this.selectedProduct.prices)
    this.selectedProductPrices.sort((a, b) => (a.to > b.to ? 1 : -1))
    this.choosenPrice =
      this.reyestrItemCopy.price_type === 'sole'
        ? this.reyestrItemCopy.soles[0].prices[0]
        : this.reyestrItemCopy?.prices[0]

    this.fetchOrderDetails()
    if (this.reyestrItemCopy.price_type === 'reyestr') {
      // for (let i = 0; i < this.reyestrItemCopy.brands.length; i++) {
      this.reyestrItemCopy?.brands.forEach((item) => {
        this.fetchBrandingById(item?.id)
          .then((res) =>
            this.branding?.push({
              ...res,
              isDisabled: true,
              productId: this.selectedReyestrItem?.id,
              reyestrId: this.reyestrItemCopy?.id,
            })
          ) // добавляю доп значение minValue чтобы делать позицию не активной если его минимальное значение больше чем колличество этого товара в корзине
          .catch((err) => {})
      })
      // }
    }
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.activeLang = this.$store.state.language
    // this.saveBrandCount([])
    const savedBrands = localStorage.getItem('bought_brands')
    if (!savedBrands) {
      localStorage.setItem('bought_brands', JSON.stringify([]))
    }
    setTimeout(() => {
      this.selectedColorPath = encodeURI(
        this.baseUrl + this.selectedColor.photo_path
      )
    }, 500)
    const h2Element = this.$refs.descriptionRef.querySelector('h2')
    if (h2Element) {
      h2Element.style.fontSize = '18px' // Например, устанавливаем размер 16px
    }
  },
}
</script>

<style lang="scss">
$gr_col: #818181;
@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.p-7 {
  padding: 30px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.info_product {
  letter-spacing: 0.2px;
}

.container_order {
  max-width: 1440px;
  margin: auto;
}

.account_title {
  @include ftz(1.5rem, 600, #31343c);
  letter-spacing: 1px;
}

.account_page {
  @include ftz(0.75rem, 400, #818181);
  position: relative;
  cursor: pointer;

  .account_vector_i::before,
  .account_vector_i_2::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    color: $gr_col;
    position: absolute;
    top: 3px;
    left: 75px;
    cursor: pointer;
    background-size: 0.45rem;
  }

  .account_vector_i_2::before {
    content: '';
    margin-left: 3.5rem;
  }

  .page_name {
    margin: 0 1.7rem;
  }
}
.video_block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.account_block {
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .left_account {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;

    @media (max-width: 1025px) {
      width: 100%;
    }

    .account_img {
      width: 100%;
      padding-top: 100%;
      background: #ffffff;
      border-radius: 1px;
      position: relative;
      cursor: pointer;
      & > div {
        width: 100%;
        position: absolute;
        inset: 0;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      @media (max-width: 1024px) {
        height: 12rem;
        @media (max-width: 769px) {
          height: 8rem;
          @media (max-width: 481px) {
            height: 10rem;
          }
        }
      }
    }

    .sale_txt {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      left: 0;
      right: 0;
      padding: 12px;
      cursor: pointer;
      top: 0;

      @media (max-width: 1025px) {
        width: 92%;
      }

      .account_sale {
        @include ftz(0.75rem, 400, #766c64);
        text-transform: capitalize;
        width: 4rem;
        height: 1.5rem;
        background: #e8d36a;
        border-radius: 1px;
        padding: 4px;
        text-align: center;
        @media (max-width: 669px) {
          width: 40px;
        }
      }

      .icon_loupe {
        width: 25px;
        height: 25px;
        background: #c7c7c7;
        border-radius: 1px;
      }
    }
    .photo__item {
      height: fit-content;

      &-content {
        cursor: pointer;

        padding-top: 100%;
        position: relative;
        border-radius: 1px;
        & > div {
          position: absolute;
          inset: 0;
          background-color: #fff;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }
        }
      }
    }
  }

  .right_account {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;

    @media (max-width: 1024px) {
      width: 100%;
    }
    .account_info {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      .info_title {
        @include ftz(1.5rem, 500, #423f3d);
        margin-top: 7px;
      }
      .info_price {
        @include ftz(20px, 500, #423f3d);
        line-height: 15px;
        font-size: 20px;
        margin-top: 7px;
      }
      .info_product {
        @include ftz(0.875rem, 400, #666666);
        h2 {
          font-size: 15px;
        }
        p {
          font-size: 15px;
        }
      }
    }

    .choose_price {
      background-color: #fff;
      display: block;
      &-item:not(:last-child) {
        margin-right: 1px;
      }
      .choose {
        @include ftz(0.875rem, 500, #666666);
      }

      .choose_ul_li {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;

        .item_choose {
          width: 16.5%;
          padding: 5px;
          .choose_btn {
            display: inline-flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: #fbfbfb;
            border-radius: 2px;
            height: 100%;
            line-height: 13px;
            font-size: 14px;
            @include ftz(0.875rem, 500, #9f9f9f);
            &--active {
              @include ftz(0.875rem, 500, #423f3d);
              background-color: #e4f1e3;

              .green_price {
                @include ftz(0.875rem, 500, #70b377);
              }
            }
            &--error {
              @include ftz(0.875rem, 500, #423f3d);
              background-color: #fbbcb8;

              .green_price {
                @include ftz(0.875rem, 500, #70b377);
              }
            }
          }

          .soles_l_grey,
          .soles_grey {
            @include ftz(0.875rem, 500, #423f3d);
          }
          .soles_l_grey {
            background: #f7f7f7;
          }
          .soles_grey {
            background: #fff;
            border: 1px solid #f3f3f3;
          }
        }
      }
    }

    .choose_colors {
      background-color: #fff;
      .colors_ttl {
        @include ftz(0.875rem, 500, #666666);
      }

      .colors_ul_li {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        @media (max-width: 2000px) {
          width: 700px;
        }
        @media (max-width: 1390px) {
          width: auto;
        }
        .colors_btn {
          cursor: pointer;
          width: 103px !important;
          height: 44px;
          margin: 1px;
          &--active {
            .colors_btn-image {
              border-bottom: 3px solid #eca8b8;
            }
          }

          .colors_btn-image[src*='loader'] {
            object-fit: contain;
            width: 50%;
            border-radius: 2px;
          }
          .colors_btn-image {
            width: 100%;
            border-radius: 2px;
            display: block;
            object-fit: cover;
            // box-sizing: content-box;
          }
        }
      }
    }

    .choose_size {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      background-color: #fff;

      .size_ttl {
        @include ftz(0.875rem, 500, #666666);

        .clear_ttl {
          width: 58px;
          height: 16px;
          @include ftz(0.625rem, 500, #a09146);
          background: #e8d36a;
          border-radius: 2px;
          padding: 3px 10px;
        }
      }
      .size_ul_li {
        .size_item {
          max-width: 23.333333%;
          .btn_choose_size {
            background: #f7f7f7;
            border: 0.2px solid #e9f5e7;
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;
            align-items: center;
            padding: 5px;

            .size_block {
              position: relative;
              display: block;
              margin-right: 2rem;

              .s_number {
                @include ftz(1.125rem, 500, #423f3d);
              }
              .s_number_ttl {
                @include ftz(0.875rem, 500, #666666);
                margin-bottom: 1rem;
                position: absolute;
              }
            }

            .btn-size {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              align-items: center;
              height: 2.2rem;
              background: #ffffff;

              .form-control {
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                &[type='number'] {
                  -moz-appearance: textfield;
                  appearance: textfield;
                }
                border: none;
                height: 35px;
                text-align: center;
                @include ftz(1rem, 400, #423f3d);
              }
            }
            .btn-number {
              padding: 6px 8px;
            }
          }
        }
      }
    }

    .customization {
      background-color: #fff;
      .v-expansion-panel-content__wrap {
        padding: 0;
      }
      &__title {
        @include ftz(17px, 800, #000);
      }
      &__radio {
        background-color: #f6f6f6;
        display: flex;
        align-items: center;
        &-text {
          display: block;
          font-size: 12px;
          line-height: 15px;
          span {
            font-size: 16px;
            display: block;
            font-weight: 700;
          }
        }
        &-btn {
          position: relative;
          font-size: 22px;
          user-select: none;
          height: 19px;
          width: 19px;

          input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 19px;
            width: 19px;
            border-radius: 50%;
            border: 1px solid #d8d7d8;
          }
          .checked .checkmark:after {
            display: none; /* Отключаем отображение галочки, когда класс .checked применен */
          }
          input:checked ~ .checkmark {
            border-color: #b8deb4;
          }

          .checkmark:after {
            width: 9px;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            height: 9px;
            border-radius: 50%;
            background-color: #b8deb4;
          }
          .checkmark::after {
            content: '';
            position: absolute;
            display: none;
          }
          input:checked ~ .checkmark:after {
            display: block;
          }
        }
      }
      .ratio__image {
        position: relative;
      }

      .ratio__button {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 24px;
        width: 40px;
        height: 40px;
        text-align: center;
        transform: rotate(180deg);
        padding: 0;
      }
      .position-item {
        &__ratio {
          padding-top: 80%;
          position: relative;
          & > div {
            position: absolute;
            inset: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }

          .ratio__checkbox {
            display: block;
            cursor: pointer;
            font-size: 22px;
            user-select: none;
            width: 15px;
            height: 15px;
            position: absolute;
            bottom: 5px;
            right: 5px;
            margin: 0;
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            input:checked ~ .checkmark {
              background-color: #002b4e;
            }

            .checkmark::after {
              left: 4px;
              top: 2px;
              width: 5px;
              height: 8px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(35deg);
              -ms-transform: rotate(35deg);
              transform: rotate(35deg);
            }

            input:checked ~ .checkmark::after {
              display: block;
            }
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: #fff;
            border: 1px black solid;
            border-radius: 2px;
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }
        }
      }
    }
    .preliminary_count {
      background: #ffffff;
      width: 100%;
      border-radius: 1px;
      display: flex;
      flex-direction: column;

      .count_ttl {
        @include ftz(17px, 600, #000);
        margin-left: 1rem;
        margin-top: 1.5rem;
      }

      .count_ul_li {
        width: 70%;
        .count_item {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;

          .count_item_ul_li {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .count_circle {
              min-width: 18px;
              height: 18px;
              border-radius: 15px;
            }

            .count_name {
              @include ftz(0.875rem, 500, #423f3d);
              text-transform: capitalize;
              margin: 0.5rem 1rem;
            }

            .check_mark::before {
              content: '';
              display: inline-block;
              width: 13px;
              height: 10px;
              background-repeat: no-repeat;
            }
            .count_dots,
            .count_dots_orange {
              min-width: 8px;
              height: 6px;
              background: #db5e5e;
              border-radius: 4px;
              margin-right: 1rem;
            }

            .count_dots_orange {
              background: #f79e32;
            }

            .count_text {
              @include ftz(0.625rem, 500, #423f3d);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.upload-logo__btn {
  text-align: center;
  width: 100%;
  height: fit-content;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 700;
  color: #666666;
  line-height: 18px;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'Alegreya Sans';
  background-color: #cfebcc;
  letter-spacing: 0.13em;
  &-disabled {
    background-color: #eee;
    color: #939393;
    cursor: default;
  }
}
fieldset {
  border: 1px solid grey;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
  legend {
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
  }
}

.v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .v-dialog__content {
//   width: 400px !important;
//   height: 300px;
// }

.modal-card {
  height: 200px; /* Устанавливаем высоту модального окна на весь экран */
}
.b-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 25px;
}

.b-details {
  font-size: 18px;
}
</style>
